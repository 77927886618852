@import "normalize";

// custom font
@font-face {
  font-family: "sunny_spellsregular";
  src: url("../static/fonts/sunnyspellsregular-mv9ze-webfont.woff2") format("woff2"),
       url("../static/fonts/sunnyspellsregular-mv9ze-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

html {
	scroll-behavior: smooth;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color: #eee;
}

/**
 * Disable smooth scrolling when users have prefers-reduced-motion enabled
 */
 @media screen and (prefers-reduced-motion: reduce) {
	html {
		scroll-behavior: auto;
	}
}

.center {
  text-align: center;
}

h2 {
  font-size: x-large;
  font-family: "sunny_spellsregular";
  color: #fff;
  font-size: 4em;
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  margin: 0;
  padding: 0 0 1em 0;
}

#header {
  height: 100vh;
}

#paralax {
  width: 100%;
  height: 100%;
  background-color: #11345b;
}

#paralax .layer {
  width: 100%;
  height: 100%;
  position: absolute;
  background-position: bottom center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

#paralax .bg {
  background-image: url("../static/images/header-bg.jpg");
}

#paralax .mg {
  background-image: url("../static/images/header-mg.png");
}

#paralax .fg {
  background-image: url("../static/images/header-fg.png");
  background-attachment: scroll;
}

#mint {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  width: 20em;
  height: 20em;
  display: flex;
  align-items: center;
  margin-top: -10em;
  margin-left: -10em;
  color: #095353;
}

#mint div {
  width: 100%;
}

#mint a {
  display: block;
  font-family: "sunny_spellsregular";
  color: #095353;
  font-size: 3em;
  text-transform: uppercase;
  border: 5px solid #095353;
  border-radius: 0.6em;
  padding: 0.3em 0.6em;
  opacity: 0.85;
  text-decoration: none;
}

#mint a:hover {
  opacity: 1;
}

#header h1 {
  display: none;
}

#about {
  background-color: #11345b;
}

#about p, #about li, #roadmap p, #faq p {
  color: #eee;
  font-size: 1.2em;
  line-height: 1.5em;
}

#about ul {
  column-count: 2;
}

.frame {
  transform: rotate(-5deg);
  padding: 65px;
}

.frame div {
  background-image: url("../static/images/frame.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.kontra {
  transform: rotate(5deg);
}

.circle {
  clip-path: circle();
}

#about, #roadmap, #faq {
  max-width: 80vw;
  padding: 10vw;
  margin: 0;
}

#night-sky-tree-tops img, #trees-bottom img, #ground img {
  display: block;
  min-width: 100%;
  max-width: 100%;
}

#roadmap {
  background-color: #095152;
}

#team {
  background-color: #cf5a45;
  padding-bottom: 1em;
}

#team ul {
  list-style: none;
  flex-wrap: wrap;
}

#team li {
  width: 23vw;
  padding-bottom: 3em;
  text-align: center;
}

#team figure {
  padding: 0;
  margin: 0;
}

#team h3 {
  font-weight: lighter;
  font-size: 2em;
  font-family: "sunny_spellsregular";
  text-transform: uppercase;
}

#team .dev h3 {
  font-family: 'Courier New', Courier, monospace;
  text-transform: none;
  font-size: 2em;
}

#team .member p {
  font-size: 1.2em;
  font-style: italic;
  margin-top: -1.4em;
}

#team .member em {
  font-weight: normal;
  font-size: 0.8em;
  display: block;
  padding-top: 0.5em;
}

#team .member img {
  width: 150px;
}

#team .member a img {
  width: 20px;
  opacity: 0.8;
}

#team .member a:hover img {
  opacity: 1;
}

#faq, #footer {
  background-color: #802a35;
}

#faq h3 {
  color: #fff;
}

#faq p {
  font-size: 0.9em;
  display: none;
  opacity: 0.8;
  margin-top: 0;
}

#faq .open p {
  display: block;
}

#faq figure div {
  display: flex;
  justify-content: space-between;
}

#faq figure span {
  padding: 1.2em 0;
}

#faq figure {
  margin-bottom: 1em;
}

#faq .faq-item {
  border: 1px solid #cc5a66;
  border-radius: 10px;
  padding: 0.5em 1.5em 0.5em 1.2em;
  cursor: pointer;
}

#faq .open {
  border: 1px solid #a9323e;
}

#footer {
  display: flex;
  justify-content: space-between;
}

#footer p {
  text-align: center;
  margin: 0;
  padding: 2em;
  opacity: 0.6;
  font-size: 0.9em;
}

#footer span {
  font-size: 1.2em;
  position: relative;
  top: -6px;
  padding-right: 5px;
}

#footer img {
  width: 25px;
  padding: 0 5px;
}

/* 320px width */
@media screen and (min-width: 20em) {
  .flex {
    display: block;
  }

  figure {
    margin: 0;
    margin-block: 0;
  }

  #team li {
    width: 80vw;
  }

  #about ul {
    column-count: 1;
  }

  .frame img {
    width: 200px;
  }

  .frame {
    padding: 15px;
  }

  #about p, #about li, #roadmap p, #faq p {
    font-size: 1em;
  }
}
/* 460px */
@media screen and (min-width: 28.75em) {
  .frame img {
    width: 300px;
  }

  #team li {
    width: 40vw;
  }

  #team .flex {
    display: flex;
  }

  .frame {
    padding: 30px;
  }
}
@media screen and (min-width: 39em) {
  .flex {
    display: flex;
    justify-content: space-around;
  }

  .frame {
    padding: 65px;
  }

  figure {
    padding: 1em;
    margin: 1em;
  }

  #about ul {
    column-count: 2;
  }

  #team li {
    width: 23vw;
  }

  #about p, #about li, #roadmap p, #faq p {
    font-size: 1.2em;
  }
}